<template>
  <div class="board-page">
    <v-tabs fixed-tabs>
      <v-tab class="non-focused"
        ><strong>For Class</strong
        ><v-icon
          small
          class="ml-2"
          @click="selectedRoom = null"
          v-if="selectedRoom && !currentUser.is_student"
          >mdi-pencil</v-icon
        ></v-tab
      >
      <v-tab class="non-focused"
        ><strong v-if="currentUser.is_student">For You</strong
        ><strong v-else>Students</strong></v-tab
      >
      <v-tab-item><classroom-messages :room="selectedRoom" /></v-tab-item>
      <v-tab-item :key="studentMessagesKey">
        <div v-if="currentUser.is_student">
          <individual-student-messages v-if="currentUser.is_student" />
        </div>
        <div v-else><student-messages-list :room="selectedRoom" /></div>
      </v-tab-item>
    </v-tabs>
    <choose-room-dialog
      :visible="!selectedRoom"
      @roomSelected="(r) => (selectedRoom = r)"
    />
  </div>
</template>
<script>
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import ClassroomMessages from "@components/board/ClassroomMessages.vue";
import StudentMessagesList from "@components/board/StudentMessagesList.vue";
import EventBus from "@utils/event_bus";
import { mapGetters } from "vuex";
import IndividualStudentMessages from "@views/board/IndividualStudentMessages";
import Helper from "@utils/misc";
import Mixins from "@utils/mixins";
export default {
  name: "Messages",
  components: {
    ChooseRoomDialog,
    ClassroomMessages,
    StudentMessagesList,
    IndividualStudentMessages,
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      EventBus.$emit("keep_alive__destroy_view", "Messages");
    }
    next();
  },
  mixins: [Mixins.handleAppBarOptions],
  data() {
    return {
      selectedRoom: null,
      studentMessagesKey: null,
      classroomMessagesKey: null,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    appBarOptions() {
      return {
        iconButton: { icon: "mdi-refresh", action: this.onRefresh },
      };
    },
  },
  methods: {
    refreshStudentsList() {
      this.studentMessagesKey = Helper.guidGenerator();
    },
    async onRefresh() {
      var room  = this.selectedRoom;
      this.selectedRoom = null;
      await this.$nextTick()
      this.selectedRoom = room;
    },
  },
  watch: {
    selectedRoom: "refreshStudentsList",
  },
  created() {
    this.selectedRoom = this.currentUser.is_student
      ? this.currentUser.room
      : null;
  },
};
</script>
